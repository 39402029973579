<template>
  <div class="container py-5">
    <p class="fw-bold">IMCAS 2024</p>
    <p>時間：2024/02/01-03</p>
    <p>地點：Palais des Congrès de Paris</p>
    <p>展位：F110 </p>

    <p>
     Join us at #IMCAS2024 from February 1st to February 3rd to find out more about Observ 520x! Will we have the pleasure of meeting you there? 
    <br>
     Booth: F110 
    <br>
     Visit our daily live demonstrations and engaging presentations at our booth.
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_33.jpg"
    />


  </div>
</template>
<script>
export default {};
</script>
